<template>
    <header>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-6 col-md-3 order-1 order-md-1">
                    <router-link :to="'/'"
                        ><img src="/img/logo.svg" class="logo" />
                    </router-link>
                </div>
                <div
                    class="col-6 col-md-auto text-uppercase order-3 order-md-2 d-none d-lg-block"
                >
                    <div><strong>ügyfélszolgálat</strong></div>
                    <strong
                        ><a href="tel:+36 20 454 1008"
                            >+36 20 454 1008</a
                        ></strong
                    >
                </div>
                <div
                    class="col-6 col-md-auto text-uppercase order-4 order-md-3 d-none d-lg-block"
                >
                    <div><strong>kapcsolat</strong></div>
                    <strong
                        ><a href="mailto:info@gumidiszkont.hu"
                            >info@gumidiszkont.hu</a
                        ></strong
                    >
                </div>
                <div class="col-6 col-md order-2 order-md-4">
                    <a href="https://lacigumi.hu" target="_blank"
                        ><img src="/img/lacigumi_ads.png" class="top-ads" />
                    </a>
                </div>
                <div
                    class="col-12 col-md-auto text-end text-uppercase order-3 order-md-5"
                >
                    <div class="text-grey d-none d-md-block">
                        nyárigumi-téligumi webáruház
                    </div>
                    <template v-if="Object.keys(user).length > 0">
                        <b-dropdown
                            id="dropdown-1"
                            text="Dropdown Button"
                            class="m-md-2"
                        >
                            <template #button-content>
                                Üdvözlünk {{ user.name }}
                            </template>
                            <b-dropdown-item
                                ><router-link :to="{ name: 'Profile' }"
                                    >Profil</router-link
                                ></b-dropdown-item
                            >
                            <b-dropdown-item
                                ><router-link :to="{ name: 'ProfileData' }"
                                    >Személyes adatok</router-link
                                ></b-dropdown-item
                            >
                            <b-dropdown-item
                                ><router-link :to="{ name: 'MyOrders' }"
                                    >Rendeléseim</router-link
                                ></b-dropdown-item
                            >
                            <b-dropdown-item
                                ><router-link
                                    :to="{ name: 'ProfileNewsletter' }"
                                    >Hírlevél</router-link
                                ></b-dropdown-item
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item v-on:click="logout"
                                >Kijelentkezés</b-dropdown-item
                            >
                        </b-dropdown>
                    </template>
                    <template v-else>
                        <strong
                            ><router-link :to="{ name: 'Login' }"
                                >Belépés</router-link
                            >
                            /
                            <router-link
                                :to="{ name: 'Registration' }"
                                class="text-black"
                                >regisztráció</router-link
                            ></strong
                        >
                    </template>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Header",
    data() {
        return {
            user: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        this.getUserData();
    },
    methods: {
        logout() {
            $cookies.remove("user");
            $cookies.remove("id_token");
            document.location.href = "/";
        },
        getUserData() {
            if ($cookies.get("id_token")) {
                ApiService.get(`${this.url}/user/profile`).then(response => {
                    this.user = response.data.data;
                    $cookies.set("user", response.data.data, 86400);
                });
            }
        }
    }
};
</script>

<style scoped>
.top-ads {
    width: 100%;
    max-height: 80px;
    object-fit: contain;
}
</style>
