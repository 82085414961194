<template>
    <div class="search-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="search">
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="text-uppercase mb-4 d-none d-md-block"
                                >
                                    <strong>Autógumi kereső</strong>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <label>Szélesség</label>
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.width"
                                            >
                                                <option :value="null">-</option>
                                                <option
                                                    v-for="(item,
                                                    index) in filters.width"
                                                    v-bind:key="
                                                        'width-' + index
                                                    "
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label>Magasság</label>
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.profile"
                                            >
                                                <option :value="null">-</option>
                                                <option
                                                    v-for="(item,
                                                    index) in filters.profile"
                                                    v-bind:key="
                                                        'profile-' + index
                                                    "
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label>Átmérő</label>
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.coll"
                                            >
                                                <option :value="null">-</option>
                                                <option
                                                    v-for="(item,
                                                    index) in filters.coll"
                                                    v-bind:key="'coll-' + index"
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src="/img/tyre.png"
                                    class="w-100 mt-2 d-none d-md-block"
                                />
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="text-uppercase mb-4 d-none d-md-block"
                                >
                                    <strong>Találatok szűkítése</strong>
                                </div>
                                <div class="row mt-4 mt-md-0">
                                    <div class="col-6">
                                        <label>Évszak</label>
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.m1nev"
                                            >
                                                <option :value="'Nyári abroncs'"
                                                    >Nyári gumi</option
                                                >
                                                <option :value="'Téli abroncs'"
                                                    >Téli gumi</option
                                                >
                                                <option
                                                    :value="
                                                        'Négyévszakos abroncs'
                                                    "
                                                    >Négyévszakos gumi</option
                                                >
                                            </select>
                                        </div>
                                        <label class="mt-4"
                                            >Terhelési index</label
                                        >
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.li"
                                            >
                                                <option :value="null"
                                                    >Mind</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in filters.li"
                                                    v-bind:key="'li-' + index"
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label>Márka</label>
                                        <div class="custom-select">
                                            <multiselect
                                                v-if="
                                                    filters &&
                                                        filters.emarka &&
                                                        filters.emarka.length >
                                                            0
                                                "
                                                v-model="form.emarka"
                                                :options="filters.emarka"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :preserve-search="true"
                                                placeholder="Márka választás"
                                                label="emarka"
                                                track-by="emarka"
                                                group-values="libs"
                                                group-label="label"
                                                :group-select="true"
                                                selectLabel=""
                                                selectGroupLabel=""
                                                selectedLabel=""
                                                deselectLabel=""
                                                deselectGroupLabel=""
                                                :preselect-first="false"
                                            >
                                                <template
                                                    slot="selection"
                                                    slot-scope="{
                                                        values,
                                                        search,
                                                        isOpen
                                                    }"
                                                    ><span
                                                        class="multiselect__single"
                                                        v-if="
                                                            values.length &&
                                                                !isOpen
                                                        "
                                                        >{{
                                                            values.length
                                                        }}
                                                        márka kiválasztva</span
                                                    ></template
                                                >
                                            </multiselect>
                                        </div>
                                        <!--<div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.emarka"
                                            >
                                                <option :value="null"
                                                    >Mind</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in filters.emarka"
                                                    v-bind:key="
                                                        'emarka-' + index
                                                    "
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>-->
                                        <label class="mt-4"
                                            >Sebesség index</label
                                        >
                                        <div class="custom-select">
                                            <select
                                                class="form-control"
                                                v-model="form.si"
                                            >
                                                <option :value="null"
                                                    >Mind</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in filters.si"
                                                    v-bind:key="'si-' + index"
                                                >
                                                    {{ item }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4 align-items-end">
                                    <div class="col-md-6">
                                        <div>
                                            <label class="d-block">
                                                <input
                                                    type="checkbox"
                                                    name="xlfr"
                                                    v-model="form.xlfr"
                                                />
                                                Erősített kivitel
                                            </label>
                                            <label class="d-block">
                                                <input
                                                    type="checkbox"
                                                    name="defektt"
                                                    v-model="form.defektt"
                                                />
                                                Defekttűrő rendszer
                                            </label>
                                            <label class="d-block">
                                                <input
                                                    type="checkbox"
                                                    name="tat"
                                                    v-model="form.tat"
                                                />
                                                Legalább 4 elérhető
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <button
                                            class="btn btn-primary w-100 mt-4"
                                            type="button"
                                            v-on:click="goToListPage"
                                            v-bind:class="{
                                                disabled:
                                                    parseInt(numberOfProducts) <
                                                    1
                                            }"
                                        >
                                            Keresés ({{ numberOfProducts }})
                                        </button>
                                        <small
                                            ><a
                                                style="cursor: pointer"
                                                v-on:click="setDefaultSearch"
                                                >Alaphelyzetbe</a
                                            ></small
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "TyreSearch",
    components: { multiselect: Multiselect },
    data() {
        return {
            filters: {
                coll: {},
                profile: {},
                width: {},
                emarka: {},
                m1nev: {},
                si: {},
                li: {}
            },
            form: {
                coll: 16,
                profile: 55,
                width: 205,
                emarka: null,
                m1nev: "Téli abroncs",
                si: null,
                li: null,
                defektt: null,
                xlfr: null,
                tat: true
            },
            numberOfProducts: 0
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    watch: {
        form: {
            handler: function() {
                this.getCountData();
            },
            deep: true
        },
        $route: {
            handler: function() {
                this.setSearchParams();
            },
            deep: true
        }
    },
    beforeMount() {
        this.setSearchParams();
        this.getSearchData();
    },
    mounted() {
        this.setSearchParams();
    },
    methods: {
        setDefaultSearch() {
            this.form = {
                coll: 16,
                profile: 55,
                width: 205,
                emarka: null,
                m1nev: "Téli abroncs",
                si: null,
                li: null,
                defektt: null,
                xlfr: null,
                tat: true
            };
        },
        setEmarka() {
            if (this.$route.query.emarka) {
                this.form.emarka = [];
                let helper = this.$route.query.emarka.split(",");
                for (const it in this.filters.emarka) {
                    console.log(this.filters.emarka[it]);
                    for (const kit in this.filters.emarka[it].libs) {
                        for (const xit in helper) {
                            if (
                                this.filters.emarka[it].libs[kit].emarka ===
                                helper[xit]
                            ) {
                                this.form.emarka.push(
                                    this.filters.emarka[it].libs[kit]
                                );
                            }
                        }
                    }
                }
            } else {
                this.form.emarka = [];
                const pathHelper = this.$route.params.slug.split("-");
                if (
                    pathHelper[1] === "nyari" ||
                    pathHelper[1] === "teli" ||
                    pathHelper[1] === "negyevszakos"
                ) {
                    for (const it in this.filters.emarka) {
                        for (const kit in this.filters.emarka[it].libs) {
                            if (
                                this.filters.emarka[it].libs[kit].emarka ===
                                pathHelper[0]
                            ) {
                                this.form.emarka.push(
                                    this.filters.emarka[it].libs[kit]
                                );
                            }
                        }
                    }
                }
            }
        },
        setSearchParams() {
            if (this.$route.name === "ProductList") {
                if (this.$route.query.m1nev) {
                    this.form.m1nev = this.$route.query.m1nev;
                }
                if (this.$route.query.li) {
                    this.form.li = this.$route.query.li;
                }
                if (this.$route.query.si) {
                    this.form.si = this.$route.query.si;
                }

                this.setEmarka();

                if (this.$route.query.width) {
                    this.form.width = this.$route.query.width;
                } else this.form.width = null;
                if (this.$route.query.profile) {
                    this.form.profile = this.$route.query.profile;
                } else this.form.profile = null;
                if (this.$route.query.coll) {
                    this.form.coll = this.$route.query.coll;
                } else this.form.coll = null;

                if (this.$route.query.xlfr) {
                    this.form.xlfr = this.$route.query.xlfr;
                } else this.form.xlfr = null;

                if (this.$route.query.defektt) {
                    this.form.defektt = this.$route.query.defektt;
                } else this.form.defektt = null;

                if (this.$route.query.tat) {
                    this.form.tat = this.$route.query.tat;
                } else this.form.tat = null;

                if (this.$route.path.includes("teli-gumi")) {
                    this.form.m1nev = "Téli abroncs";
                }

                if (this.$route.path.includes("nyari-gumi")) {
                    this.form.m1nev = "Nyári abroncs";
                }

                if (this.$route.path.includes("negyevszakos-gumi")) {
                    this.form.m1nev = "Négyévszakos abroncs";
                }

                const helper = this.$route.params.slug.split("-");
                if (!isNaN(parseInt(helper[0]))) {
                    this.form.width = helper[0];
                    this.form.profile = helper[1];
                    this.form.coll = helper[2];
                } else if (
                    helper[1] === "nyari" ||
                    helper[1] === "teli" ||
                    helper[1] === "negyevszakos"
                ) {
                    this.setEmarka();
                }

                if (
                    helper[0] === "nyari" ||
                    helper[0] === "teli" ||
                    helper[0] === "negyevszakos"
                ) {
                    if (helper.length > 3) {
                        this.form.width = helper[2];
                        this.form.profile = helper[3];
                        this.form.coll = helper[4];
                    }
                }

                if (
                    helper[1] === "nyari" ||
                    helper[1] === "teli" ||
                    helper[1] === "negyevszakos"
                ) {
                    if (helper.length > 3) {
                        this.form.width = helper[3];
                        this.form.profile = helper[4];
                        this.form.coll = helper[5];
                    }
                }
            }
        },
        goToListPage() {
            let helper = "";

            for (const it in this.form.emarka) {
                helper += this.form.emarka[it].emarka + ",";
            }

            helper = helper.replace(/,\s*$/, "");

            let m1nevHelper = null;
            switch (this.form.m1nev) {
                case "Nyári abroncs":
                    m1nevHelper = "nyari-gumi";
                    break;
                case "Téli abroncs":
                    m1nevHelper = "teli-gumi";
                    break;
                case "Négyévszakos abroncs":
                    m1nevHelper = "negyevszakos-gumi";
                    break;
            }

            let queryHelper = {};
            if (helper) {
                queryHelper.emarka = helper;
            }
            if (this.form.si) {
                queryHelper.si = this.form.si;
            }
            if (this.form.li) {
                queryHelper.li = this.form.li;
            }
            if (this.form.defektt) {
                queryHelper.defektt = this.form.defektt;
            }
            if (this.form.xlfr) {
                queryHelper.xlfr = this.form.xlfr;
            }
            if (this.form.tat) {
                queryHelper.tat = this.form.tat;
            }

            if (
                this.form.emarka.constructor === Array &&
                this.form.emarka.length === 1
            ) {
                m1nevHelper = this.form.emarka[0].emarka + "-" + m1nevHelper;
                delete queryHelper["emarka"];
            }

            this.$router.push({
                name: "ProductList",
                params: {
                    slug:
                        m1nevHelper +
                        "-" +
                        this.form.width +
                        "-" +
                        this.form.profile +
                        "-" +
                        this.form.coll
                },
                query: queryHelper
            });
        },
        getSearchData() {
            const self = this;
            self.$http
                .get(`${self.url}/products/search`)
                .then(response => {
                    self.filters = response.data;
                    self.setEmarka();
                })
                .finally(() => {
                    self.getCountData();
                });
        },
        getCountData() {
            const self = this;

            let formHelper = JSON.parse(JSON.stringify(self.form));
            let helper = "";

            if (formHelper.emarka && formHelper.emarka.constructor === Array) {
                for (const it in formHelper.emarka) {
                    helper += formHelper.emarka[it].emarka + ",";
                }
            } else {
                helper = formHelper.emarka;
            }

            if (helper) {
                helper = helper.replace(/,\s*$/, "");
                formHelper.emarka = helper;
            }

            this.$http
                .get(
                    `${self.url}/products/product-count?${self.buildQueryString(
                        formHelper
                    )}`
                )
                .then(response => {
                    this.numberOfProducts = response.data.products;
                });
        }
    }
};
</script>

<style scoped></style>
