<template>
    <div id="app" v-bind:class="{ nosearch: !showSearch }">
        <Header />
        <Menu />
        <TyreSearch v-if="showSearch" />
        <router-view />
        <Newsletter />
        <b-modal
            id="added-to-cart-modal"
            ref="added-to-cart-modal"
            title="Sikeres kosárhoz adás!"
            hide-footer
            centered
        >
            <div class="d-block">
                <div class="row align-items-center">
                    <div class="col-12">
                        <p>A terméket sikeresen a kosárhoz adtad.</p>
                    </div>
                    <div class="col">
                        <button v-on:click="hideModal" class="btn btn-link">
                            Vásárlás folytatása
                        </button>
                    </div>
                    <div class="col text-end">
                        <button v-on:click="goToCart" class="btn btn-primary">
                            Kosár megnyitása
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import Newsletter from "@/components/Newsletter";
import Footer from "@/components/Footer";
import TyreSearch from "@/components/TyreSearch";
export default {
    components: { TyreSearch, Footer, Newsletter, Menu, Header },
    methods: {
        hideModal() {
            this.$bvModal.hide("added-to-cart-modal");
        },
        goToCart() {
            this.$bvModal.hide("added-to-cart-modal");
            this.$router.push({ name: "Cart" });
        }
    },
    computed: {
        showSearch: function() {
            if (
                this.$route.name === "Login" ||
                this.$route.name === "Blog" ||
                this.$route.name === "BlogPost" ||
                this.$route.name === "Registration" ||
                this.$route.name === "ForgetPassword" ||
                this.$route.name === "LostPassword" ||
                this.$route.name === "ProfileData" ||
                this.$route.name === "Profile" ||
                this.$route.name === "MyOrders" ||
                this.$route.name === "ProfileNewsletter" ||
                this.$route.name === "Cart" ||
                this.$route.name === "CheckoutBilling" ||
                this.$route.name === "CheckoutShipping" ||
                this.$route.name === "CheckoutPayment" ||
                this.$route.name === "CheckoutFinish" ||
                this.$route.name === "CheckoutSuccess" ||
                this.$route.name === "Static"
            ) {
                return false;
            }

            return true;
        }
    }
};
</script>
