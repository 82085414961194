import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

function checkLoggedIn(to, from, next) {
    if ($cookies.get("id_token") && $cookies.get("user")) {
        next();
    } else {
        next("/login");
    }
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/login",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },
    {
        path: "/forgot-password",
        name: "ForgetPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "../views/ForgetPassword.vue"
            )
    },
    {
        path: "/change_password/:token",
        name: "LostPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ "../views/LostPassword.vue"
            )
    },
    {
        path: "/registration",
        name: "Registration",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "registration" */ "../views/Registration.vue"
            )
    },
    {
        path: "/profile",
        name: "Profile",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    },
    {
        path: "/profile/data",
        name: "ProfileData",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/ProfileData.vue")
    },
    {
        path: "/profile/orders",
        name: "MyOrders",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/MyOrders.vue")
    },
    {
        path: "/profile/newsletter",
        name: "ProfileNewsletter",
        beforeEnter: checkLoggedIn,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "profile" */ "../views/ProfileNewsletter.vue"
            )
    },
    {
        path: "/compare",
        name: "Compare",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "compare" */ "../views/Compare.vue")
    },
    {
        path: "/cart",
        name: "Cart",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "cart" */ "../views/Cart.vue")
    },
    {
        path: "/checkout/billing",
        name: "CheckoutBilling",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ "../views/CheckoutBilling.vue"
            )
    },
    {
        path: "/checkout/shipping",
        name: "CheckoutShipping",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ "../views/CheckoutShipping.vue"
            )
    },
    {
        path: "/checkout/payment",
        name: "CheckoutPayment",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ "../views/CheckoutPayment.vue"
            )
    },
    {
        path: "/checkout/finish",
        name: "CheckoutFinish",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ "../views/CheckoutFinish.vue"
            )
    },
    {
        path: "/payment-finish",
        name: "CheckoutSuccess",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ "../views/CheckoutSuccess.vue"
            )
    },
    {
        path: "/blog",
        name: "Blog",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/Blog.vue")
    },
    {
        path: "/cikk/:slug",
        name: "BlogPost",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "cikk" */ "../views/BlogPost.vue")
    },
    {
        path: "/termek/:slug/:emarka/:width/:profile/:coll",
        name: "Product",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "product" */ "../views/Product.vue")
    },
    {
        path: "/kapcsolat",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/altalanos-informaciok",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/kapcsolat",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/altalanos-szerzodesi-feltetelek",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/adatvedelmi-tajekoztato",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/gyakran-ismetelt-kerdesek",
        name: "Static",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "static" */ "../views/Static.vue")
    },
    {
        path: "/:slug",
        name: "ProductList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "products" */ "../views/ProductList.vue"
            )
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
