<template>
    <div class="content">
        <!--<div class="alert-box">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="alert-text">
                            2024.júl.17-től 2023.júl.22-ig leltár miatt a
                            kiszállítás szünetel.
                        </div>
                    </div>
                </div>
            </div>
        </div>-->

        <section class="my-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h1 class="title">
                            Gumidiszkont autógumi kereső <br />
                            gumiabroncs akár másnapi kiszállítással
                        </h1>
                    </div>
                    <div class="col-md-4 text-center text-md-end">
                        <a href="#" class="btn btn-primary w-50 my-3"
                            >Keresés</a
                        >
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="box-black text-center">
                            <h2 class="title-small">Téli gumi</h2>
                            <div class="title-smaller mt-4">Méret szerint</div>
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-155-70-13' }
                                }"
                                >155 70 r13</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-175-65-14' }
                                }"
                                >175 65 r14</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-195-65-15' }
                                }"
                                >195 65 r15</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-205-55-16' }
                                }"
                                >205 55 r16</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-225-55-17' }
                                }"
                                >225 55 r17</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-225-50-18' }
                                }"
                                >225 50 r18</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-245-40-19' }
                                }"
                                >245 40 r19</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-255-35-20' }
                                }"
                                >255 35 r20</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-265-45-21' }
                                }"
                                >265 45 r21</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'teli-gumi-285-40-22' }
                                }"
                                >285 40 r22</router-link
                            >
                            <div class="title-smaller mt-4">Márka szerint</div>
                            <a href="/termek/15570R13TSA/Saetta/155/70/13"
                                >SAETTA GUMIABROBNCS - 155/70 R 13 T SA
                                WINTER</a
                            ><br />
                            <a href="/termek/16570R14TW452C/Hankook/165/70/14/"
                                >HANKOOK GUMIABRONCS - 165/70 R 14 T W452 WINTER
                                ICEPT RS2</a
                            ><br />
                            <a href="/termek/17565R14THP2/Kleber/175/65/14/"
                                >KLEBER GUMIABRONCS - 175/65 R 14 T KRISALP
                                HP2</a
                            ><br />
                            <a href="/termek/18560R15TPOL3/Barum/185/60/15/"
                                >BARUM GUMIABRONCS - 185/60 R 15 T POLARIS3</a
                            ><br />
                            <a href="/termek/19565R15TWH2/Nexen/195/65/15/"
                                >NEXEN GUMIABRONCS - 195/65 R 15 T WINGUARD
                                SNOWG WH2</a
                            ><br />
                            <a href="/termek/20555R16HWS5/Dunlop/205/55/16/"
                                >DUNLOP GUMIABRONCS - 205/55 R 16 H SP WINTER
                                SPORT 5</a
                            ><br />
                            <a href="/termek/21555R17HA5/Michelin/215/55/17/"
                                >MICHELIN GUMIABRONCS - 215/55 R 17 H ALPN 5</a
                            ><br />
                            <a
                                href="/termek/24545R18V850PXL/Continental/245/45/18/"
                                >CONTINENTAL GUMIABRONCS - 245/45 R 18 V TS 850P
                                XL</a
                            ><br />
                            <a href="/termek/25540R19VSOTZ3X/Pirelli/255/40/19/"
                                >PIRELLI GUMIABRONCS - 255/40 R 19 V SOTTOZERO 3
                                XL</a
                            ><br />
                            <a href="/termek/25535R20WS954X/Toyo/255/35/20/"
                                >TOYO GUMIABRONCS - 255/35 R 20 W S954 SNOWPROX
                                XL</a
                            ><br />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="box-black text-center">
                            <h2 class="title-small">Nyári gumi</h2>
                            <div class="title-smaller mt-4">Méret szerint</div>
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-155-70-13' }
                                }"
                                >155 70 r13</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-175-65-14' }
                                }"
                                >175 65 r14</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-195-65-15' }
                                }"
                                >195 65 r15</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-205-55-16' }
                                }"
                                >205 55 r16</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-225-55-17' }
                                }"
                                >225 55 r17</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-225-50-18' }
                                }"
                                >225 50 r18</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-245-40-19' }
                                }"
                                >245 40 r19</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-255-35-20' }
                                }"
                                >255 35 r20</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-265-45-21' }
                                }"
                                >265 45 r21</router-link
                            >,
                            <router-link
                                :to="{
                                    name: 'ProductList',
                                    params: { slug: 'nyari-gumi-285-40-22' }
                                }"
                                >285 40 r22</router-link
                            >
                            <div class="title-smaller mt-4">Márka szerint</div>
                            <a href="/termek/15570R13TNE3/Toyo/155/70/13/"
                                >TOYO GUMIABRONCS - 155/70 R 13 T NANOENERGY
                                3</a
                            ><br />
                            <a href="/termek/17565R14TKH27/Kumho/175/65/14/"
                                >KUMHO GUMIABRONCS - 175/65 R 14 T KH27 ES01</a
                            ><br />
                            <a href="/termek/18560R15HZE914/Falken/185/60/15/"
                                >FALKEN GUMIABRONCS - 185/60 R 5 H ZE914</a
                            ><br />
                            <a href="/termek/19565R15TBRL2/Barum/195/65/15/"
                                >BARUM GUMIABRONCS - 195/65 R 15 T
                                BRILLANTIS2</a
                            ><br />
                            <a href="/termek/20555R16HNBHDNX/Nexen/205/55/16/"
                                >NEXEN GUMIABRONCS - 205/55 R 16 H N-BLUE HD</a
                            ><br />
                            <a href="/termek/22545R17YF1AS3/Goodyear/225/45/17/"
                                >GOODYEAR GUMIABRONCS - 225/45 R 17 Y EAGLE F1
                                ASSIMETRIC 3 FP</a
                            ><br />
                            <a href="/termek/23545R18YKU39X3/Kumho/235/45/18/"
                                >KUMHO GUMIABRONCS - 235/45 R 18 Y KU39 ECSTA
                                LESPORT XL</a
                            ><br />
                            <a href="/termek/24540R19YPI3XL/Michelin/245/40/19/"
                                >MICHELIN GUMIABRONCS - 245/40 R 19 Y PILOT
                                SPORT 3 XL </a
                            ><br />
                            <a
                                href="/termek/24535R20YS1X/Bridgestone/245/35/20/"
                                >BRIDGESTONE GUMIABRONCS - 245/35 R 20 Y S001
                                XL</a
                            ><br />
                            <a href="/termek/27530R20YSGTROF/Dunlop/275/30/20/"
                                >DUNLOP GUMIABRONCS - 275/30 R 20 Y SP SPORT
                                MAXX GT XL MFS ROF* </a
                            ><br />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="winter-tyres">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title text-center mb-5">Téli gumi</h2>
                        <ul class="list-pills">
                            <template v-for="(item, index) in model.marka">
                                <li v-bind:key="'teli-marka-' + index">
                                    <router-link
                                        :to="{
                                            name: 'ProductList',
                                            params: {
                                                slug:
                                                    item.toLowerCase() +
                                                    '-teli-gumi'
                                            }
                                        }"
                                    >
                                        {{ item }} téli gumi</router-link
                                    >
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="my-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8">
                        <h1 class="title">
                            Gumidiszkont autógumi kereső <br />
                            gumiabroncs akár másnapi kiszállítással
                        </h1>
                    </div>
                    <div class="col-md-4 text-center text-md-end">
                        <a href="#" class="btn btn-primary w-50 my-3"
                            >Keresés</a
                        >
                    </div>
                </div>
            </div>
        </section>
        <section class="summer-tyres">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title text-center mb-5">Nyári gumi</h2>
                        <ul class="list-pills" v-if="model && model.marka">
                            <template v-for="(item, index) in model.marka">
                                <li v-bind:key="'teli-marka-' + index">
                                    <router-link
                                        :to="{
                                            name: 'ProductList',
                                            params: {
                                                slug:
                                                    item.toLowerCase() +
                                                    '-nyari-gumi'
                                            }
                                        }"
                                    >
                                        {{ item }} nyári gumi</router-link
                                    >
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 position-relative">
                        <div class="box-black h-100">
                            <div class="title-small mb-4 text-center">
                                Miért mi?
                            </div>
                            <div
                                class="title-smaller"
                                v-if="model && model.shipping"
                            >
                                Szállítási díj: {{ model.shipping.price }} Ft /
                                db
                            </div>
                            <p>
                                Amennyiben legalább 4 gumiabroncsot rendel,
                                délig leadott rendelését a következő munkanapon
                                megkapja Főszezonban (minden év március
                                11-május15.-ig és október 10.-december 31.-ig) a
                                futárszolgálatok "extrém terhelése" esetén 4-5
                                munkanap alatt tudják kiszállítani a megrendelt
                                gumikat. Kérjük ennek figyelembe vételét a
                                rendelés leadásakor
                            </p>
                            <div class="title-smaller">
                                Vegye át személyesen
                            </div>
                            <p>átvételi pontunkon</p>
                            <div class="title-smaller">
                                100% magyar cég vagyunk, 19 éve gumiszereléssel
                                és értékesítéssel foglalkozunk
                            </div>
                            <p>
                                így magyar áfás számlát (27 %)állítunk ki minden
                                eladásról
                            </p>
                            <div class="title-smaller">30.000 féle abroncs</div>
                            <p>személy, kisteher, teher, motor gumi, felnik</p>
                            <div class="title-smaller">
                                Pénzvisszafizetési garancia
                            </div>
                            <p>ha mégsem elégedett a vásárlással</p>
                        </div>
                    </div>
                    <div class="col-md-6 position-relative">
                        <div class="box-black h-100 px-0 pb-0">
                            <div class="title-small mb-4 text-center">
                                ÁTVÉTELI PONTOK
                            </div>
                            <p class="text-center">
                                3529 Miskolc Soltész Nagy Kálmán u. 37.
                            </p>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.7677284195656!2d20.794770615581065!3d48.09542407922015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740a01cf561797f%3A0xf46403f15cc985af!2zTWlza29sYywgU29sdMOpc3ogTmFneSBLw6FsbcOhbiB1LiAzNywgMzUyOQ!5e0!3m2!1shu!2shu!4v1644056228810!5m2!1shu!2shu"
                                class="map"
                                allowfullscreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-md-5">
            <div class="title text-center my-5">Blog</div>
            <div class="container">
                <div class="row" v-if="model && model.blog">
                    <template v-for="(item, index) in model.blog">
                        <BlogRow v-bind:key="'blog-' + index" :blog="item" />
                    </template>
                </div>
            </div>
        </section>
        <hr class="my-md-5" />
        <section class="py-5">
            <div class="container">
                <div
                    class="row"
                    v-if="model && model.page"
                    v-html="model.page.content"
                ></div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BlogRow from "@/components/BlogRow";

export default {
    name: "Home",
    components: { BlogRow },
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(`${this.url}/page/home`).then(response => {
                this.model = response.data;
                this.metaCreation(this.model.page);
            });
        }
    }
};
</script>
