import ApiService from "@/core/services/api.service";

export default {
    computed: {
        apiUrl: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        addToCart(product_id, quantity) {
            const cart_uuid = $cookies.get("cart_uuid");
            ApiService.put(`${this.apiUrl}/cart`, {
                product_id: product_id,
                quantity: quantity,
                uuid: cart_uuid
            })
                .then(resp => {
                    if (!resp.errors && !resp.data.errors) {
                        this.$bvModal.show("added-to-cart-modal");
                    }
                })
                .finally(() => {
                    this.$eventHub.$emit("updateCart", true);
                });
        },
        formatNumber(number) {
            let num = new Intl.NumberFormat("hu-HU", {
                style: "currency",
                currency: "HUF"
            }).format(number);

            let helper = num.split(",");

            return helper[0];
        },
        metaCreation(pageData) {
            let metaTags = [];

            if (pageData.meta_title) {
                metaTags["meta_title"] =
                    pageData.meta_title + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.meta_title + " - " + process.env.VUE_APP_APP_NAME;
                this.createOrReplaceMeta(
                    "og:title",
                    pageData.meta_title + " - " + process.env.VUE_APP_APP_NAME
                );
            } else if (pageData.title) {
                metaTags["meta_title"] =
                    pageData.title + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.title + " - " + process.env.VUE_APP_APP_NAME;
                this.createOrReplaceMeta(
                    "og:title",
                    pageData.title + " - " + process.env.VUE_APP_APP_NAME
                );
            } else if (pageData.name) {
                metaTags["meta_title"] =
                    pageData.name + " - " + process.env.VUE_APP_APP_NAME;
                document.title =
                    pageData.name + " - " + process.env.VUE_APP_APP_NAME;
                this.createOrReplaceMeta(
                    "og:title",
                    pageData.name + " - " + process.env.VUE_APP_APP_NAME
                );
            } else {
                metaTags["meta_title"] = process.env.VUE_APP_APP_NAME;
                document.title = process.env.VUE_APP_APP_NAME;
                this.createOrReplaceMeta(
                    "og:title",
                    process.env.VUE_APP_APP_NAME
                );
            }

            metaTags["og:image"] =
                process.env.VUE_APP_APP_URL + pageData.og_image;
            metaTags["meta_description"] = pageData.meta_description;
            this.createOrReplaceMeta(
                "og:description",
                pageData.meta_description
            );

            if (!pageData.og_image) {
                if (pageData.image) {
                    metaTags["og:image"] =
                        process.env.VUE_APP_APP_URL + pageData.image;
                } else if (pageData.main_image) {
                    metaTags["og:image"] =
                        process.env.VUE_APP_APP_URL + pageData.main_image;
                } else {
                    metaTags["og:image"] =
                        process.env.VUE_APP_APP_URL + "/img/logo.png";
                }
            }

            if (!pageData.meta_description) {
                metaTags["meta_description"] = pageData.lead;
                this.createOrReplaceMeta("og:description", pageData.lead);
            }

            for (const it in metaTags) {
                if (document.getElementsByName(it).length < 1) {
                    const tag = document.createElement("meta");
                    tag.setAttribute("name", it);
                    tag.setAttribute("content", metaTags[it]);
                    tag.setAttribute("data-vue-router-controlled", "");

                    document.head.appendChild(tag);
                } else {
                    document
                        .getElementsByName(it)[0]
                        .setAttribute("content", metaTags[it]);
                    document
                        .getElementsByName(it)[0]
                        .setAttribute("data-vue-router-controlled", "");
                }
            }
        },
        setMetaTags(data) {
            document.title =
                data.meta_title + " - " + process.env.VUE_APP_APP_NAME;

            if (document.getElementsByName("robots").length > 0) {
                document.getElementsByName("robots")[0].remove();
            }

            this.createOrReplaceMeta(
                "og:title",
                data.meta_title + " - " + process.env.VUE_APP_APP_NAME
            );
            this.createOrReplaceMeta("description", data.meta_description);
            this.createOrReplaceMeta("og:description", data.meta_description);
            if (data.og_image) {
                this.createOrReplaceMeta("og:image", data.og_image);
            } else {
                this.createOrReplaceMeta(
                    "og:image",
                    process.env.VUE_APP_APP_URL + "/img/logo.png"
                );
            }
            this.createOrReplaceMeta("og:url", document.location.href);
        },
        getPageDataSeo() {
            const self = this;
            ApiService.get(
                "/page" + (self.$route.path !== "/" ? self.$route.path : "")
            ).then(response => {
                self.pageData = response.data.data;
                self.metaCreation(self.pageData);
            });
        },
        historyGetByRoute(path) {
            let history = localStorage.getItem("urlHistoryList");
            if (!history || !history.length) {
                return path;
            }
            history = JSON.parse(history);
            if (!history[path]) {
                return path;
            }
            return history[path];
        },
        createOrReplaceMeta(it, data) {
            if (document.getElementsByName(it).length < 1) {
                const tag = document.createElement("meta");
                tag.setAttribute("name", it);
                tag.setAttribute("content", data);
                tag.setAttribute("data-vue-router-controlled", "");

                document.head.appendChild(tag);
            } else {
                document.getElementsByName(it)[0].setAttribute("content", data);
                document
                    .getElementsByName(it)[0]
                    .setAttribute("data-vue-router-controlled", "");
            }
        },
        buildQueryString(params) {
            var esc = encodeURIComponent;
            var query = Object.keys(params)
                .map(k => esc(k) + "=" + esc(params[k]))
                .join("&");

            return query;
        },
        m1NevSwitcher(m1nev) {
            switch (m1nev) {
                case "Nyári abroncs":
                    return "Nyári gumi";
                case "Téli abroncs":
                    return "Téli gumi";
                case "Négyévszakos abroncs":
                    return "Négyévszakos gumi";
                default:
                    return m1nev;
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
};
