<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="footer-title mt-4 mt-md-0">Kapcsolat</div>
                    <a href="tel:+36 20 454 1008">+36 20 454 1008</a>
                    <br />
                    <a href="mailto:info@gumidiszkont.hu"
                        >info@gumidiszkont.hu</a
                    >
                </div>
                <div class="col-md-3" v-if="model && model.items">
                    <div class="footer-title mt-4 mt-md-0">Információk</div>
                    <template v-for="(item, index) in model.items">
                        <a v-bind:key="'menu-2-' + index" :href="item.link">{{
                            item.title
                        }}</a>
                        <br v-bind:key="'menu-3-' + index" />
                    </template>
                </div>
                <div class="col-md-3">
                    <div class="footer-title mt-4 mt-md-0">Fizetési módok</div>
                    Személyes
                    <br />
                    Készpénz <br />
                    Bankkártya
                </div>
                <div class="col-md-3">
                    <img
                        v-lazy="'/img/payment-logos.png'"
                        class="w-100 mt-4 mt-md-0"
                    />
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Footer",
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(`${this.url}/menu/get/2`).then(response => {
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
