import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { SET_ERRORS } from "@/core/services/store/defaultStore";
import toastr from "toastr";
import i18n from "@/core/plugins/vue-i18n";
import store from "@/core/services/store";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        if (process.env.NODE_ENV === "production") {
            Vue.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}`;
        }
        Vue.axios.defaults.withCredentials = true;

        Vue.axios.interceptors.response.use(
            function(response) {
                if (response.config.method !== "get") {
                    toastr.options = {
                        closeButton: true,
                        debug: false,
                        newestOnTop: false,
                        progressBar: false,
                        positionClass: "toast-bottom-right",
                        preventDuplicates: true,
                        onclick: null,
                        showDuration: "300",
                        hideDuration: "1000",
                        timeOut: "5000",
                        extendedTimeOut: "1000",
                        showEasing: "swing",
                        hideEasing: "linear",
                        showMethod: "fadeIn",
                        hideMethod: "fadeOut"
                    };

                    if (
                        !response.headers.hide_alert &&
                        response.headers.hide_alert !== "1"
                    ) {
                        toastr.success(
                            i18n.t("Siker!"),
                            i18n.t("Művelet sikeresen végrehajtva")
                        );
                    }
                }
                if (JwtService.getToken()) {
                    JwtService.saveToken(JwtService.getToken());
                }

                store.dispatch(SET_ERRORS, []);

                return response;
            },
            function(error) {
                toastr.options = {
                    closeButton: true,
                    debug: false,
                    newestOnTop: false,
                    progressBar: false,
                    positionClass: "toast-bottom-right",
                    preventDuplicates: true,
                    onclick: null,
                    showDuration: "300",
                    hideDuration: "1000",
                    timeOut: "5000",
                    extendedTimeOut: "1000",
                    showEasing: "swing",
                    hideEasing: "linear",
                    showMethod: "fadeIn",
                    hideMethod: "fadeOut"
                };

                if (error.response.data && error.response.data.errors) {
                    store.dispatch(SET_ERRORS, error.response.data.errors);
                }

                if (error.response.status && error.response.status === 401) {
                    toastr.error(
                        i18n.t("Hozzáférés megtagadva!"),
                        i18n.t("Ehhez a művelethez nincs hozzáférése.")
                    );
                } else if (
                    error.response.status &&
                    error.response.status === 422
                ) {
                    var errorText = "";
                    $.each(error.response.data.errors, function(key, value) {
                        errorText += value + "<br/>";
                    });
                    toastr.error(
                        i18n.t("Hiba!") + "<br/>" + errorText,
                        i18n.t("Kérem javítsa a hibákat.")
                    );
                } else if (
                    error.response.status &&
                    error.response.status === 419
                ) {
                    // document.location.reload();
                } else {
                    if (
                        !error.response.config.url.startsWith("/storage/") &&
                        error.response.status !== 404
                    ) {
                        toastr.error(
                            i18n.t("Hiba!"),
                            i18n.t("Valami hiba történt.")
                        );
                    }
                }

                return Promise.reject(error);
            }
        );
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;

        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", "hu");
        }

        Vue.axios.defaults.headers.common["Language"] = localStorage.getItem(
            "language"
        );

        Vue.axios.defaults.headers.common["X-Requested-With"] =
            "XMLHttpRequest";

        const token = document.head.querySelector('meta[name="csrf-token"]');
        if (token) {
            Vue.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
        } else {
            if (JwtService.getXSRFToken()) {
                Vue.axios.defaults.headers.common[
                    "X-CSRF-TOKEN"
                ] = JwtService.getXSRFToken();
            } else {
                console.error(
                    "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
                );
            }
        }
    },

    query(resource, params) {
        this.setHeader();
        return Vue.axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        this.setHeader();
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        this.setHeader();
        return Vue.axios.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        this.setHeader();
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        this.setHeader();
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        this.setHeader();
        return Vue.axios.delete(resource).catch(error => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
