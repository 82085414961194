<template>
    <div class="col-md-4 my-3">
        <img :src="appUrl + blog.main_image" class="w-100" />

        <div class="text-small mt-2 mb-4">
            {{ blog.publication_date }}
        </div>
        <div class="title-smaller">
            {{ blog.title }}
        </div>
        <p>
            {{ blog.lead }}
        </p>
        <router-link :to="{ name: 'BlogPost', params: { slug: blog.slug } }"
            >Olvass tovább</router-link
        >
    </div>
</template>

<script>
export default {
    name: "BlogRow",
    props: ["blog"],
    computed: {
        appUrl: function() {
            return process.env.VUE_APP_APP_URL;
        }
    }
};
</script>

<style scoped></style>
