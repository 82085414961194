<template>
    <nav>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-12">
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <ul
                            v-if="model && model.items"
                            v-bind:class="{ showMenu: showMenu }"
                        >
                            <template v-for="(item, index) in model.items">
                                <li
                                    v-bind:class="{
                                        active: $route.path === item.link
                                    }"
                                    v-bind:key="'menu-1-' + index"
                                >
                                    <router-link
                                        :to="item.link"
                                        class="stretched-link"
                                        >{{ item.title }}</router-link
                                    >
                                </li>
                            </template>
                        </ul>
                        <div class="menu-opener d-inline-flex d-xl-none">
                            <img src="/img/menu.svg" v-on:click="toggleMenu" />
                        </div>
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template #button-content>
                                <div class="cart-icon">
                                    <img src="/img/cart.svg" /><b-badge
                                        v-if="
                                            cart &&
                                                cart.items_count &&
                                                cart.items_count > 0
                                        "
                                        >{{ cart.items_count }}</b-badge
                                    >
                                </div>
                            </template>
                            <b-dropdown-item :to="{ name: 'Cart' }"
                                >Kosár</b-dropdown-item
                            >
                            <b-dropdown-item :to="{ name: 'CheckoutBilling' }"
                                >Pénztár</b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Menu",
    data() {
        return {
            model: {},
            cart: {},
            showMenu: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.showMenu = false;
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
        this.getCart();
    },
    mounted() {
        const self = this;
        self.$eventHub.$on("updateCart", function() {
            self.getCart();
        });
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        getSearchData() {
            ApiService.get(`${this.url}/menu/get/1`).then(response => {
                this.model = response.data.data;
            });
        },
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(
                response => {
                    $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                    this.cart = response.data.data;
                }
            );
        }
    }
};
</script>

<style scoped>
.showMenu {
    display: block;
}
</style>
