<template>
    <div class="newsletter">
        <link
            href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
            rel="stylesheet"
            type="text/css"
        />
        <form
            action="//gumidiszkont.us13.list-manage.com/subscribe/post?u=3b0a2d9aa5ccea4356a7d1692&amp;id=4faba70f33"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
        >
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-3">
                        <div class="title-small">
                            Iratkozz fel <br />
                            hírlevelünkre!
                        </div>
                    </div>
                    <div class="col-md-3">
                        <input
                            type="text"
                            value=""
                            name="FNAME"
                            placeholder="Név*"
                            class="required form-control form-underlined"
                            id="mce-FNAME"
                        />
                    </div>
                    <div class="col-md-3">
                        <input
                            type="email"
                            value=""
                            name="EMAIL"
                            placeholder="Email*"
                            class="required email form-control form-underlined"
                            id="mce-EMAIL"
                        />
                    </div>
                    <div class="col-md-3 my-3 my-md-0">
                        <input
                            type="submit"
                            value="Feliratkozom"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            class="btn btn-secondary"
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "Newsletter"
};
</script>

<style scoped></style>
